<script setup lang="ts">
import ProductList from '~/components/plp/ProductList.vue';
import FilterBar from '~/components/filter-bar/index.vue';
import { useRoute } from 'vue-router';
import {
	fetchBrandProducts,
	fetchNewArrivalsProducts,
	fetchProductsCount,
	useNewArrivalsProductCarousel,
	useProductsCount,
	useProductsList,
} from '~/data/products';
import PlpHeader from '~/components/plp/Header.vue';
import Description from '~/components/plp/Description.vue';
import { useNodesQuery } from '~/data/nodes';
import { Separator } from 'radix-vue';

import NodesBar from '~/components/plp/NodesBar.vue';
import SeoProvider from '~/components/plp/SeoProvider.vue';
import { useStatsigExperiment } from '~/utils/use-statsig-experiment';
import { useFilterParams } from '~/utils/use-filter-param';
import WebPageSeo from '~/components/seo/WebPageSeo.vue';

const { $cathodeClient } = useNuxtApp();
const route = useRoute();
const { storeType } = useGlobalStore();

const { newArrivalsFilterExperiment } = useStatsigExperiment();

const routeName = 'brands-handle';
const handle = route.params.handle as string;

const filters = useFilterParams(routeName);

const { data: nodes, suspense } = useNodesQuery({
	entity: 'brands',
	handle: handle,
});

onServerPrefetch(async () => {
	await suspense();
});
const count = useProductsCount({
	routeName,
	queryKey: ['nodes', handle, filters],
	queryFn: () =>
		fetchProductsCount({
			brand_handle: handle!,
			...filters.value,
		}),
});

const {
	data,
	isLoading,
	hasNextPage,
	fetchNextPage,
	isFetchingNextPage,
	error: productListError,
} = useProductsList({
	queryKey: ['brands', handle, filters],
	queryFn: ({ pageParam: cursor }) => {
		return fetchBrandProducts(handle, cursor as string, filters.value);
	},
	routeName,
});

const isNewArrivalCarouselEnabled = computed(() => {
	return newArrivalsFilterExperiment?.value?.is_active ?? false;
});

const { data: newArrivalsCarousel } = useNewArrivalsProductCarousel({
	queryKey: ['products-carousel', handle],
	queryFn: () => {
		return fetchNewArrivalsProducts({
			carousel_type: 'new-arrivals',
			brand_handle: handle,
			period: newArrivalsFilterExperiment?.value?.period ?? '30d',
		});
	},
	enabledOverride: isNewArrivalCarouselEnabled,
});

onMounted(() => {
	$cathodeClient.queueEvent('page_load', {
		source: `${window.location.origin}${route.path}`,
		page: 'PLP',
		statusCode: 200,
		referrer: document.referrer,
		collection_handle: route.params.handle as string,
	});
});

watch(isLoading, (isLoading) => {
	if (!isLoading && productListError.value) {
		throw createError({
			statusCode: 404,
			message: 'Failed to load products',
			data: {
				url: route.fullPath,
			},
			fatal: true,
		});
	}
});

// watch(isNewArrivalsLoading, (isLoading) => {
// 	if (!isLoading && newArrivalsProductListError.value) {
// 		throw createError({
// 			statusCode: 404,
// 			message: 'Failed to load products',
// 			data: {
// 				url: route.fullPath,
// 			},
// 			fatal: true,
// 		});
// 	}
// });

// const getNewArrivalsHeader = computed(() => {
// 	return (title: string | undefined, count: number) => {
// 		if (!newArrivalsFilterExperiment?.value?.is_active) {
// 			return undefined;
// 		}
// 		if (newArrivalsCarousel.value && newArrivalsCarousel.value.count < 7) {
// 			return undefined;
// 		}
// 		if (title) {
// 			return {
// 				title: `New Arrivals in ${title}`,
// 				subtitle: `${count} products`,
// 			};
// 		}
// 		return undefined;
// 	};
// });

// const getAllProductsHeader = computed(() => {
// 	return (title: string | undefined) => {
// 		if (!newArrivalsFilterExperiment?.value?.is_active) {
// 			return undefined;
// 		}
// 		if (newArrivalsCarousel.value && newArrivalsCarousel.value.count < 7) {
// 			return undefined;
// 		}
// 		if (isNewArrivalEnabled.value) {
// 			return {
// 				title: `More in ${title}`,
// 				subtitle: '',
// 			};
// 		}
// 		if (title) {
// 			return {
// 				title: `All Products in ${title}`,
// 				subtitle: '',
// 			};
// 		}
// 		return undefined;
// 	};
// });
</script>
<template>
	<SeoProvider v-slot="{ seo }" :route-name="routeName" :handle="handle">
		<WebPageSeo
			:data="seo"
			:item-list-data="data?.pages"
			:handle="handle"
			:count="count"
		/>
		<div>
			<PlpHeader :data="seo" :items-count="count" />
			<ClientOnly>
				<div
					v-if="nodes && nodes.length > 0"
					class="flex flex-col gap-3xl border-b border-gray-200 py-3xl"
				>
					<NodesBar
						:feed-nodes="nodes"
						:show-new="
							newArrivalsFilterExperiment?.is_active &&
							newArrivalsCarousel &&
							newArrivalsCarousel.count > 6
								? true
								: false
						"
					/>
				</div>
				<FilterBar :handle="handle" />
				<!-- <ProductCarousel
					v-if="
						!isNewArrivalsLoading &&
						newArrivalsCarousel &&
						newArrivalsCarousel.count > 6 &&
						!isNewArrivalEnabled
					"
					:data="newArrivalsCarousel"
					:header="
						getNewArrivalsHeader(seo?.title, newArrivalsCarousel?.count || 0)
					"
				/> -->
				<!-- <ProductList
					v-if="isNewArrivalEnabled"
					:infinite-loader="true"
					:fetch-next-page="newArrivalsFetchNextPage"
					:is-loading="isNewArrivalProductsLoading"
					:data="newArrivalProducts"
					:has-next-page="newArrivalsHasNextPage"
					:is-fetching-next-page="newArrivalsIsFetchingNextPage"
				/> -->
				<ProductList
					:infinite-loader="true"
					:fetch-next-page="fetchNextPage"
					:is-loading="isLoading"
					:data="data"
					:has-next-page="hasNextPage"
					:is-fetching-next-page="isFetchingNextPage"
					:scroll-threshold="0.6"
				/>
				<template #fallback>
					<FilterbarSkeleton />
					<ProductListSkeleton />
				</template>
			</ClientOnly>

			<div
				v-if="
					!!seo?.description &&
					!!seo?.title &&
					storeType === STORE_TYPE.MARKETPLACE
				"
				class="w-full"
			>
				<Separator class="seo-description-separator" />
				<Description :description="seo.description" :heading="seo.title" />
			</div>
		</div>
	</SeoProvider>
</template>

<style scoped>
.seo-description-separator {
	@apply !bg-gray-100 h-xl mt-5xl;
}
</style>
